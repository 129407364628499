import $ from 'jquery';
import slick from 'slick-carousel';

const $slick = $(".slick");

if($slick.length > 0) {
    $slick.filter('.infinite-dots').slick({
        arrows: false,
        dots: true,
        fade: true
    });
}
